<template>
  <div class="stat fullwidth pa-2">
    <div class="mb-4 fullwidth flex">
      <div class="report-label flex-1">
        Contract Address / Ens
      </div>
      <div class="flex value align-center fullwidth flex-2">
        {{ contractData.address || '--' }} /
        {{ contractData.ens || '--' }}
        <GlImg
          v-if="addressData.contractInfo && addressData.contractInfo.icon"
          v-tooltip.top="{
            content: `${addressData.contractInfo.name}`,
          }"
          class="ml-2"
          :src-val="addressData.contractInfo && addressData.contractInfo.icon"
        />
      </div>
    </div>
    <div class="mb-4 fullwidth flex">
      <div class="report-label flex-1">
        Contract Deployer / Ens
      </div>
      <div class="flex value align-center fullwidth flex-2">
        {{
          (contractData.contractDeployer &&
            contractData.contractDeployer.address) ||
            '--'
        }}
        /
        {{
          (contractData.contractDeployer &&
            contractData.contractDeployer.ens) ||
            '--'
        }}
      </div>
    </div>
    <div class="mb-4 fullwidth flex">
      <div class="report-label fs-14 flex-1">
        Contract Owner / Ens
      </div>
      <div class="flex value align-center fullwidth flex-2">
        {{
          (contractData.contractOwner && contractData.contractOwner.address) ||
            '--'
        }}
        /
        {{
          (contractData.contractOwner && contractData.contractOwner.ens) || '--'
        }}
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlImg from '@/components/gl-img.vue'

// utils
import { isOwnerExist } from '@/utils/report-data-formatter'

export default {
  name: 'EthContractEnsBlock',
  components: {
    GlImg,
  },
  props: {
    addressData: {
      type: Object,
      default: () => {},
    },
    contractData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    isOwnerExist,
  },
}
</script>
