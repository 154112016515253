<template>
  <div>
    <Section class="mb-4">
      <template #title>
        <div>
          Address Information
          <MonitoringButton v-if="addressData.isMonitoring" />
        </div>
      </template>
      <template #body>
        <div
          v-if="!hasTxs && !addressDataLoading"
          class="wallet-address-wrap pa-2"
        >
          There are no transactions for this address
        </div>
        <div class="pa-2">
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            inline-view
            label="Wallet address"
            :loading="addressDataLoading"
            :value="`${(addressData && addressData.address) || address} / ${
              contractData.ens || '--'
            }`"
          />
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            inline-view
            label="Type"
            :loading="addressDataLoading"
            value="Contract"
          />
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            inline-view
            label="Token Balance"
            :loading="addressDataLoading"
            :value="
              addressData &&
                `${toComaSeparate(String(restrictNumberAfterComma(addressData.balance.amount, 8)))} ${selectedToken.symbol}`
            "
          />
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            inline-view
            label="Number of TXS"
            :loading="addressDataLoading"
            :value="(addressData && toComaSeparate(String(addressData.txCount))) || '0'"
          />
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            inline-view
            label="Total tx sent"
            :loading="addressDataLoading"
            :value="(addressData && toComaSeparate(String(addressData.txOutputsCount))) || '0'"
          />
          <InfoBlock
            flex-second-column-two
            inline-view
            label="Total tx received"
            :loading="addressDataLoading" 
            :value="(addressData && toComaSeparate(String(addressData.txInputsCount))) || '0'"
          />
        </div>
      </template>
    </Section>
    <Section class="mb-4">
      <template #title>
        <div> Owner Information </div>
      </template>
      <template #body>
        <div v-if="isOwnerExist(addressData)">
          <ReportSectionBody
            :data="addressData"
            :data-description="addressData.description"
            label-tag="Extra context tags towards the classification of the requested address"
            label-top="Owner / Contract Name"
            label-type="Type of entity that owns requested address"
            :owner-name="`${
              (addressData && addressData.owner) ||
              (contractData && contractData.owner) ||
              '--'
            } / ${
              (addressData &&
                addressData.contractInfo &&
                addressData.contractInfo.name) ||
              (contractData &&
                contractData.contractInfo &&
                contractData.contractInfo.name) ||
              '--'
            }`"
            :tag-meta-data="addressData.meta"
            :type-data="addressData.type"
          />

          <hr class="mt-2 mb-2 pale-grey-border">
          <EthContractEnsBlock
            :address-data="addressData"
            :contract-data="contractData"
          />
        </div>
        <div
          v-else
          class="pa-2"
        >
          Not identified
        </div>
      </template>
    </Section>
  </div>
</template>

<script>
// Components
import Section from '@/components/gl-section'
import ReportSectionBody from '@/pages/report/components/ReportSectionBody'
import MonitoringButton from '@/pages/report/components/MonitoringButton'
import EthContractEnsBlock from '@/pages/report/components/eth-section-body/EthContractEnsBlock'
import InfoBlock from '@/components/gl-info-block'

// utils
import { isOwnerExist } from '@/utils/report-data-formatter'
import { toComaSeparate, restrictNumberAfterComma } from "@/utils/formatNumber";

export default {
  name: 'EthSectionContractInfo',
  components: {
    MonitoringButton,
    Section,
    ReportSectionBody,
    InfoBlock,
    EthContractEnsBlock
  },
  props: {
    addressData: {
      type: Object,
      default: () => {},
    },
    contractData: {
      type: Object,
      default: () => {},
    },
    hasTxs: {
      type: Boolean,
      default: false,
    },
    addressDataLoading: {
      type: Boolean,
      default: false,
    },
    address: {
      type: [String],
      default: '',
    },
    selectedToken: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ownerContractLabel() {
      return this.addressData.owner
    },
  },
  methods: {
    isOwnerExist,
    toComaSeparate,
    restrictNumberAfterComma
  },
}
</script>
